<template>
  <div>
    <LayoutModelCreate
      v-bind="$store.state.stone_model[_modelName]"
      :extendPostData="_extendPostData"
      :params="_params"
      :label="_label"
      :urlModelName="_urlModelName"
    ></LayoutModelCreate>
  </div>
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
    _params() {
      let _params = {};
      if (this.$route.meta.params) {
        _params = this.$route.meta.params;
      }
      return _params;
    },
    _extendPostData() {
      return this.$route.meta.params ? this.$route.meta.params : {};
    },
    _label() {
      let _label;
      if (this.$route.meta.label) {
        _label = this.$route.meta.label;
      } else {
        _label = this.$store.state.stone_model[this._modelName].label;
      }
      return _label;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() {},
};
</script>